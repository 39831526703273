import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import ComplaintsComplianceNav from '../../../components/SubPageNav/ComplaintsComlianceNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'

const PotentialRegistrarActions = () => {
  const intl = useIntl()
  return (
    <Layout
      view='builder'
      breadcrumb={{
        text: <FormattedMessage id='builderNav.dashboard' />,
        path: '/builder-vendor/dashboard/'
      }}
      header={<FormattedMessage id='builderComplaints4.headerA' />}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'builderNav.complaints' })}
      />
      <Container>
        <Row>
          <Col lg={3} md={3}>
            <ComplaintsComplianceNav activePath='/builder-vendor/complaints-compliance-enforcement/potential-registrar-actions/' />
          </Col>

          <Col>
            <Row>
              <Col>
                <p style={{ marginTop: `0` }}>
                  <FormattedMessage id='builderComplaints4.contentA' />{' '}
                  <Link to='/licensing-compliance/regulatory-activites-enforcement/'>
                    <FormattedMessage id='builderComplaints4.contentAi' />
                  </Link>
                  :
                </p>
                <ul>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05C_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05C' />
                  </li>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05D_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05D' />
                  </li>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05H_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05H' />
                  </li>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05E_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05E' />
                  </li>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05F_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05F' />
                  </li>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05G_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05G' />
                  </li>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05N_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05Ni' />{' '}
                    <a href={intl.formatMessage({ id: 'linkNames.nhclaLink' })}>
                      <FormattedMessage id='linkNames.nhclaAlt' />
                    </a>
                    <FormattedMessage id='complaintsProcess.list05Nii' />{' '}
                    <a
                      href={intl.formatMessage({ id: 'linkNames.onhwpaLink' })}
                    >
                      <FormattedMessage id='linkNames.onhwpa' />
                    </a>{' '}
                    <FormattedMessage id='complaintsProcess.list05Niii' />{' '}
                    <a href={intl.formatMessage({ id: 'linkNames.poaLink' })}>
                      <FormattedMessage id='linkNames.poa' />
                    </a>
                    . <FormattedMessage id='complaintsProcess.list05Niv' />
                  </li>
                  <li>
                    <b>
                      <FormattedMessage id='complaintsProcess.list05K_B' />
                    </b>
                    <br />
                    <FormattedMessage id='complaintsProcess.list05K' />{' '}
                    <Link to='/about/discipline-and-appeals-committees/'>
                      <FormattedMessage id='complaintsProcess.list05Ki' />
                    </Link>{' '}
                    <FormattedMessage id='complaintsProcess.list05Kii' />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='builderNav.complaints1' />,
                path:
                  '/builder-vendor/complaints-compliance-enforcement/complaints/'
              }}
              nextLink={{
                text: <FormattedMessage id='adminPenalties.header' />,
                path:
                  '/builder-vendor/complaints-compliance-enforcement/administrative-penalty/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default PotentialRegistrarActions
